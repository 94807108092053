import React from "react";
import "./Navbar.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {
  const userid = useSelector((state) => state.userid);

  return (
    <div className="my-navbar">
      <NavLink to={`/${userid}`}>
        <img src="/image/logo.jpg" alt="" />
      </NavLink>
    </div>
  );
};

export default Navbar;
