import React, { useState, useEffect } from "react";
import "./Homepage.css";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import { NavLink, useParams } from "react-router-dom";
import {updateUserId} from '../../Store/UserIdReducer'
import { useDispatch } from "react-redux";

const Homepage = () => {
  const {userid} = useParams();
  const dispatch = useDispatch();

  const [allCategories, setAllCategories] = useState([]);

  const backendUrl = process.env.REACT_APP_BACKEND_URL;

  useEffect(() => {
    const getAllCat = async () => {
      try {
        const data = await axios.get(`${backendUrl}/categories-parent`);

        // let sortedCategories = data.data.filter((c) => c.parent == null);
        setAllCategories(data.data);
      } catch (error) {}
    };

    getAllCat();

    dispatch(updateUserId({userid: userid}))
  }, []);
  console.log(allCategories);

  return (
    <div className="my-homepage">
      <Container>
        <div className="home-logo">
          <img src="/image/logo.jpg" alt="" />
        </div>

        <div className="my-homepage-caterories">
          <Row>
            {allCategories.map((e, i) => (
              <Col key={i} xs={6} s={6} md={6} lg={6}>
                <NavLink
                  to={`/cat/${e.id}`}
                  className="my-homepage-cat"
                  href={/cat/i}>
                  <img src={e.logo} alt="" />
                  <div>{e.title}</div>
                </NavLink>
              </Col>
            ))}
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default Homepage;
