import { createSlice } from "@reduxjs/toolkit";

export const UserIdSlice = createSlice({
  name: "products",
  initialState: "",
  reducers: {
    updateUserId: (state, action) => {
      return action.payload.userid;
    },
  },
});

export const { updateUserId } = UserIdSlice.actions;

export default UserIdSlice.reducer;
